import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Gallery from '../../components/Gallery';

export default function SingleMemorials() {
  const data = useStaticQuery(graphql`
    query SingleMemorialsQuery {
      allCloudinaryMedia(
        filter: { tags: { eq: "single" } }
        sort: { fields: public_id, order: ASC }
      ) {
        nodes {
          id
          secure_url
        }
      }
    }
  `);

  return (
    <Gallery
      galleryImages={data.allCloudinaryMedia.nodes}
      galleryName="Single Memorials"
      title="Single Memorials Gallery | Van Gemert Memorials"
      description="Photo gallery of single memorials that we have made for our customers."
      urlPath="single-memorials"
    />
  );
}
